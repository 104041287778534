<template>
  <el-dialog
    :title="editingMemory ? $t('flashCard.i18n22') : $t('flashCard.i18n21')"
    :visible.sync="visible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closeMemoryFormDialogVisible"
    width="400px"
  >
    <el-form ref="form" :model="memory">
      <el-form-item prop="memory" :rules="[commonRules.input]">
        <el-input
          type="textarea"
          :rows="5"
          :placeholder="$t('flashCard.i18n23')"
          v-model="memory.memory"
        />
      </el-form-item>
      <el-form-item>
        <el-checkbox
          :true-label="1"
          :false-label="0"
          v-model="memory.is_public"
        >
          {{ $t("flashCard.i18n24") }}
          <el-tooltip
            :content="$t('flashCard.i18n25')"
            placement="top"
            popper-class="tooltipColor"
          >
            <span class="action-icon">
              <i class="fas fa-question-circle" />
            </span>
          </el-tooltip>
        </el-checkbox>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeMemoryFormDialogVisible">
        {{ $t("alert.cancel") }}
      </el-button>
      <el-button v-if="editingMemory" type="danger" @click="deleteVocabMemory">
        {{ $t("alert.delete") }}
      </el-button>
      <el-button type="primary" @click="handleVocabMemory">
        {{ $t("alert.save") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import formMixin from "@/mixins/form";

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    vocabularyId: {
      default: null,
      type: Number
    },
    editingMemory: {
      default: null,
      type: Object
    }
  },
  mixins: [formMixin],
  watch: {
    editingMemory(newEditingMemory) {
      if (newEditingMemory) {
        this.memory = {
          memory: newEditingMemory.memory,
          is_public: newEditingMemory.is_public
        };
      }
    }
  },
  data() {
    return {
      memory: {
        memory: "",
        is_public: 0
      }
    };
  },
  methods: {
    closeMemoryFormDialogVisible() {
      this.$emit("closeMemoryFormDialogVisible");
      this.memory = {
        memory: "",
        is_public: 0
      };
    },
    async handleVocabMemory() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        this.$message.error(this.$t("message.form_validation_error"));
        return;
      }
      try {
        if (this.editingMemory) {
          await this.$store.dispatch("flashcard/updateVocabMemory", {
            vocabularyId: this.vocabularyId,
            vocabMemoryId: this.editingMemory.id,
            memory: this.memory
          });
          this.$message.success(this.$t("message.update_success"));
        } else {
          await this.$store.dispatch("flashcard/addVocabMemory", {
            vocabularyId: this.vocabularyId,
            memory: this.memory
          });
          this.$message.success(this.$t("message.create_success"));
        }
        this.closeMemoryFormDialogVisible();
      } catch (e) {
        this.$message.error(this.$t("message.something_went_wrong"));
      }
    },
    async deleteVocabMemory() {
      try {
        await this.$confirm(
          this.$t("message.areYouSure"),
          this.$t("message.notice"),
          {
            confirmButtonText: this.$t("message.continue"),
            cancelButtonText: this.$t("message.cancel"),
            type: "warning"
          }
        );
      } catch (e) {
        return false;
      }
      await this.$store.dispatch("flashcard/deleteVocabMemory", {
        vocabularyId: this.vocabularyId,
        vocabMemoryId: this.editingMemory.id
      });
      this.$message.success(this.$t("message.delete_success"));
      this.closeMemoryFormDialogVisible();
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 10px 20px;
}

::v-deep .el-form-item {
  margin-bottom: 8px;
}
</style>
