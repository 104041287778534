<template>
  <div>
    <el-drawer
      size="100%"
      class="drawer"
      :visible.sync="opened"
      direction="rtl"
      :before-close="() => $emit('closeVocabularyDrawer')"
    >
      <template slot="title">
        <div v-if="vocabulary" class="drawerTitle">
          <h1>{{ vocabulary.vocab_front.word }}</h1>
          <el-button
            type="primary"
            size="mini"
            class="playBtn"
            @click.stop="speakWord(vocabulary.vocab_front.word, 'en-US')"
          >
            US&nbsp;<i class="fas fa-volume-up" />
          </el-button>
          <el-button
            type="primary"
            size="mini"
            class="playBtn"
            @click.stop="speakWord(vocabulary.vocab_front.word, 'en-GB')"
          >
            UK&nbsp;<i class="fas fa-volume-up" />
          </el-button>
        </div>
      </template>
      <div
        v-if="vocabulary"
        class="drawerContent"
        @click="() => $emit('closeVocabularyDrawer')"
      >
        <el-divider content-position="left">
          <i class="fas fa-book book" /> {{ $t("flashCard.i18n47") }}
        </el-divider>
        <div class="information">
          {{ `(${vocabulary.pos}) ${getI18nMeaning(vocabulary.meaning)}` }}
        </div>
        <el-divider content-position="left">
          <i class="fas fa-brain" /> {{ $t("flashCard.i18n43") }}
        </el-divider>
        <div class="memories">
          <template v-if="vocabMemories.length > 0">
            <VocabMemory
              :memory="vocabMemories[0]"
              :vocabularyId="vocabularyId"
              @editVocabMemory="() => editVocabMemory(vocabMemories[0])"
            />
            <div class="memoryFooter">
              <el-button size="small" @click.stop="openVocabMemoriesDrawer">
                <i class="fas fa-eye" /> {{ $t("flashCard.i18n44") }}
              </el-button>
            </div>
          </template>
          <div v-else class="memoryFooter">
            {{`${vocabulary.vocab_front.word} ${$t("flashCard.i18n45")}`}}
            <span class="action-text" @click.stop="createVocabMeory">{{ $t("flashCard.i18n36") }}</span>
            {{ $t("flashCard.i18n46") }}
          </div>
        </div>
        <template v-if="vocabulary.sentence">
          <el-divider content-position="left">
            <i class="fas fa-comment" /> {{ $t("flashCard.i18n42") }}
          </el-divider>
          <div class="information">
            {{ vocabulary.sentence }}
          </div>
        </template>
        <el-divider content-position="left">
          <i class="fas fa-atlas" /> {{ $t("flashCard.i18n48") }}
        </el-divider>
        <div class="dictionaries">
          <el-tooltip
            class="item"
            :content="$t('flashCard.merriam-webster')"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" :href="getTodictionaryLink('merriam-webster')">
              <div class="dictionary">
                <img
                  src="@/assets/dictionary/merriam-webster.png"
                  class="dictionaryIcon"
                />
              </div>
            </a>
          </el-tooltip>
          <el-tooltip
            class="item"
            :content="$t('flashCard.cambridge')"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" :href="getTodictionaryLink('cambridge')">
              <div class="dictionary">
                <img
                  src="@/assets/dictionary/cambridge.png"
                  class="dictionaryIcon"
                />
              </div>
            </a>
          </el-tooltip>
          <el-tooltip
            class="item"
            :content="$t('flashCard.youtube')"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" :href="getTodictionaryLink('youtube')">
              <div class="dictionary">
                <img
                  src="@/assets/dictionary/youtube.png"
                  class="dictionaryIcon"
                />
              </div>
            </a>
          </el-tooltip>
          <el-tooltip
            class="item"
            :content="$t('flashCard.google')"
            placement="top"
            popper-class="tooltipColor"
          >
            <a target="_blank" :href="getTodictionaryLink('google')">
              <div class="dictionary">
                <img
                  src="@/assets/dictionary/google.png"
                  class="dictionaryIcon"
                />
              </div>
            </a>
          </el-tooltip>
        </div>
      </div>
    </el-drawer>
    <VocabMemoriesDrawer
      :visible="vocabMemoriesDrawerVisible"
      :vocabularyId="vocabularyId"
      :vocabMemories="vocabMemories"
      @createVocabMeory="createVocabMeory"
      @editVocabMemory="editVocabMemory"
      @closeVocabMemoriesDrawer="closeVocabMemoriesDrawer"
    />
    <MemoryFormDialog
      v-if="vocabulary"
      :visible="memoryFormDialogVisible"
      :vocabularyId="vocabularyId"
      :editingMemory="editingMemory"
      @closeMemoryFormDialogVisible="closeMemoryFormDialogVisible"
    />
  </div>
</template>

<script>
import MemoryFormDialog from "@/views/toefl/flashcards/MemoryFormDialog";
import VocabMemory from "@/views/toefl/flashcards/VocabMemory";
import VocabMemoriesDrawer from "@/views/toefl/flashcards/VocabMemoriesDrawer";
import formMixin from "@/mixins/form";
import { speakWord } from "@/views/toefl/flashcards/speak.js";

export default {
  props: {
    opened: {
      default: false,
      type: Boolean
    },
    vocabulary: {
      default: null,
      type: Object
    }
  },
  mixins: [formMixin],
  components: {
    MemoryFormDialog,
    VocabMemory,
    VocabMemoriesDrawer
  },
  computed: {
    speakWord() {
      return speakWord;
    },
    lang() {
      return this.$store.getters["user/langShortValue"];
    },
    vocabMemories() {
      return this.vocabulary?.vocab_memories || [];
    },
    vocabularyId() {
      return this.vocabulary?.id;
    }
  },
  data() {
    return {
      displayMyMemoryModal: "all",
      memoryFormDialogVisible: false,
      editingMemory: null,
      vocabMemoriesDrawerVisible: false
    };
  },
  created() {},
  methods: {
    openMemoryFormDialogVisible() {
      this.memoryFormDialogVisible = true;
    },
    closeMemoryFormDialogVisible() {
      this.memoryFormDialogVisible = false;
    },
    openVocabMemoriesDrawer() {
      this.vocabMemoriesDrawerVisible = true;
    },
    closeVocabMemoriesDrawer() {
      this.vocabMemoriesDrawerVisible = false;
    },
    createVocabMeory() {
      this.editingMemory = null;
      this.openMemoryFormDialogVisible();
    },
    editVocabMemory(memory) {
      this.editingMemory = { ...memory };
      this.openMemoryFormDialogVisible();
    },
    getI18nMeaning(meaning) {
      if (this.lang === "en") {
        return meaning.tw;
      } else {
        return meaning[this.lang];
      }
    },
    getTodictionaryLink(dictionary) {
      switch (dictionary) {
        case "youtube":
          return `https://www.youtube.com/results?search_query=${this.vocabulary.vocab_front.word}`;
        case "google":
          return `https://www.google.com/search?q=${this.vocabulary.vocab_front.word}&tbm=isch&sclient=img`;
        case "cambridge":
          return `https://dictionary.cambridge.org/zht/%E8%A9%9E%E5%85%B8/%E8%8B%B1%E8%AA%9E-%E6%BC%A2%E8%AA%9E-%E7%B9%81%E9%AB%94/${this.vocabulary.vocab_front.word}`;
        case "merriam-webster":
          return `https://www.merriam-webster.com/dictionary/${this.vocabulary.vocab_front.word}`;
        default:
      }
    }
  }
};
</script>

<style scoped lang="scss">
.drawerTitle {
  display: flex;
  align-items: center;

  h1 {
    color: var(--themeColor);
  }

  .playBtn {
    margin-left: 8px;
    font-size: 16px;
  }
}

.drawerContent {
  padding: 0px 20px 20px 20px;
  height: 100%;
  cursor: pointer;
}

::v-deep .el-divider--horizontal {
  margin-top: 32px;
}

::v-deep .el-divider__text.is-left {
  left: 0;
  font-size: 28px;
  color: var(--themeColor);
  padding: 0px;
}

.memories {
  .memoryFooter {
    text-align: center;
  }
}

.information {
  padding: 12px;
  background-color: #ecf6f0;
  color: var(--themeColor);
  border-radius: 4px;
}

.dictionaries {
  display: flex;
  color: var(--themeColor);
  .dictionary {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 4px;

    .dictionaryIcon {
      width: 60px;
      height: 60px;
    }
  }

  .dictionary:hover {
    opacity: 0.7;
  }
}
</style>
