<template>
  <div>
    <el-drawer
      class="drawer"
      :visible.sync="visible"
      direction="rtl"
      :before-close="() => $emit('closeVocabMemoriesDrawer')"
    >
      <template slot="title">
        <div class="drawerTitle">
          {{ $t("flashCard.i18n32") }}
        </div>
      </template>
      <div class="controlMemory">
        <el-button-group>
          <el-button
            size="small"
            :type="displayMyMemoryModal === 'all' ? 'primary' : ''"
            @click.stop="() => displayMyMemoryModal = 'all'"
          >
            {{ $t("flashCard.i18n33") }}
          </el-button>
          <el-button
            size="small"
            :type="displayMyMemoryModal === 'ICreated' ? 'primary' : ''"
            @click.stop="() => displayMyMemoryModal = 'ICreated'"
          >
            {{ $t("flashCard.i18n34") }}
          </el-button>
          <el-button
            size="small"
            :type="displayMyMemoryModal === 'ILiked' ? 'primary' : ''"
            @click.stop="() => displayMyMemoryModal = 'ILiked'"
          >
            {{ $t("flashCard.i18n35") }}
          </el-button>
        </el-button-group>
        <el-button size="small" @click.stop="() => $emit('createVocabMeory')">
          <i class="fas fa-plus"></i> {{ $t("flashCard.i18n36") }}
        </el-button>
      </div>
      <div class="memories">
        <VocabMemory
          :key="vocabMemory.id"
          v-for="vocabMemory in displayVocabMemories"
          :memory="vocabMemory"
          :vocabularyId="vocabularyId"
          @editVocabMemory="memory => $emit('editVocabMemory', memory)"
        />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import VocabMemory from "@/views/toefl/flashcards/VocabMemory";

export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    vocabularyId: {
      default: null,
      type: Number
    },
    vocabMemories: {
      default: () => [],
      type: Array
    }
  },
  components: {
    VocabMemory
  },
  computed: {
    displayVocabMemories() {
      switch (this.displayMyMemoryModal) {
        case "all":
          return this.vocabMemories;
        case "ICreated":
          return this.vocabMemories.filter(({ do_i_created }) => do_i_created);
        case "ILiked":
          return this.vocabMemories.filter(({ do_i_liked }) => do_i_liked);
        default:
          return [];
      }
    }
  },
  data() {
    return {
      displayMyMemoryModal: "all"
    };
  },
  created() {},
  methods: {}
};
</script>

<style scoped lang="scss">
::v-deep .el-drawer {
  width: 560px !important;
}

.drawerTitle {
  font-weight: bold;
}
.controlMemory {
  display: flex;
  justify-content: space-between;
  padding: 0px 20px;
  margin-bottom: 12px;
}

.memories {
  padding: 0px 20px;
}
</style>
