<template>
  <div :class="`memory ${memory.do_i_created ? 'myMemory' : ''}`">
    <div v-if="memory.do_i_created" class="edit">
      <span
        class="action-icon"
        @click.stop="() => $emit('editVocabMemory', memory)"
      >
        <i class="fas fa-edit" />
      </span>
    </div>
    <div class="content">{{ memory.memory }}</div>
    <div class="like">
      <div
        v-if="memory.do_i_liked"
        class="action-icon likedIcon"
        @click.stop="() => switchLikeVocabMemory(memory.id)"
      >
        <i class="fas fa-thumbs-up" />
      </div>
      <div
        v-else
        class="action-icon"
        @click.stop="() => switchLikeVocabMemory(memory.id)"
      >
        <i class="far fa-thumbs-up" />
      </div>
      <div>
        {{ memory.vocab_memory_likes_count }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    memory: {
      default: () => ({}),
      type: Object
    },
    vocabularyId: {
      default: null,
      type: Number
    }
  },
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    switchLikeVocabMemory(vocabMemoryId) {
      this.$store.dispatch("flashcard/likeVocabMemory", {
        vocabularyId: this.vocabularyId,
        vocabMemoryId
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.memory {
  margin-bottom: 16px;
  background-color: #ecf6f0;
  color: var(--themeColor);
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 60px;
}

.myMemory {
  grid-template-columns: 60px 1fr 60px;
}

.edit {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #DCDFE6;
  font-size: 20px;
}

.content {
  margin: 20px 0px 20px 12px;
}

.like {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-left: 1px solid #DCDFE6;
  font-size: 20px;

  .likedIcon {
    color: orange;
  }
}
</style>